import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { functions } from "../../../../shared/config/firebase";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type LocationAnalyticsProps = {
  locationUid: string;
};

export const LocationAnalytics = ({ locationUid }: LocationAnalyticsProps) => {
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [hourlyVisits, setHourlyVisits] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [endDate, setEndDate] = useState<string>(new Date().toISOString().split("T")[0]);

  useEffect(() => {
    getLocationVisits();
  }, [startDate, endDate]);

  const getLocationVisits = async () => {
    const getLocationVisits = httpsCallable(functions, "getLocationVisits");
    try {
      const { data } = await getLocationVisits({ locationUid });
      const visits = data
      processVisitsData(visits);
    } catch (error) {
      console.error("Error fetching location visits: ", error);
    }
  };

  const processVisitsData = (visitsData: any) => {
    const labels: string[] = [];
    const visits: number[] = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
    const localTimeOffset = new Date().getTimezoneOffset() / -60;

    let currentDate = new Date(start);
    while (currentDate <= end) {
      const year = currentDate.getFullYear().toString();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const day = currentDate.getDate().toString().padStart(2, "0");

      if (visitsData[year]?.[month]?.[day]) {
        const hours = visitsData[year][month][day];
        for (let hour = 0; hour < 24; hour++) {
          const hourString = hour.toString().padStart(2, "0");
          const visitCount = hours[hourString] || 0;

          const localHour = (hour + localTimeOffset + 24) % 24;
          const label = `${year}-${month}-${day} ${localHour.toString().padStart(2, "0")}:00`;
          labels.push(label);
          visits.push(visitCount);
        }
      } else {
        for (let hour = 0; hour < 24; hour++) {
          const localHour = (hour + localTimeOffset + 24) % 24;
          const label = `${year}-${month}-${day} ${localHour.toString().padStart(2, "0")}:00`;
          labels.push(label);
          visits.push(0);
        }
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    setChartLabels(labels);
    setHourlyVisits(visits);
  };

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Visits per hour",
        data: hourlyVisits,
        backgroundColor: "rgba(63, 59, 242, .5)",
        borderColor: "rgb(134, 78, 255)",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: { beginAtZero: true },
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          maxTicksLimit: 16, // Limit the number of ticks shown
          callback: (value: string | number) => {
            const date = chartLabels[value as number];
            return date;
          },
        },
      },
    },
  };

  return (
    <div>
      <div className="flex space-x-4 mb-4">
        <div>
          <label>Start Date</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="border p-2"
          />
        </div>
        <div>
          <label>End Date</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="border p-2"
          />
        </div>
      </div>
      <div>
        <h2>Total visits within date: {hourlyVisits.reduce((sum, visit) => sum + visit, 0)}</h2>
      </div>
      <div style={{ height: "400px" }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

