type SingleInputProps = {
  label?: string;
  type: string;
  value?: string | number;
  onChange: (e: string | number) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  icon?: string;
  className?: string;
};

export const SingleInput = (props: SingleInputProps) => {
  return (
    <div className="flex flex-col">
      <label
        className="text-left text-lg text-gray-900 dark:text-white"
        htmlFor={props.label}
      >
        {props.label}
      </label>
      <div className="relative">
        <div className="absolute left-[3%] top-[57%] -translate-y-1/2">
          <i
            className={`${props.icon} text-2xl dark:text-gray-500 text-gray-300`}
          />
        </div>
        <input
          className={
            props.className
              ? props.className
              : `block indent w-full ${props.icon ? 'px-12': 'px-3'} py-3 mt-2
text-gray-700 bg-white border border-gray-300 rounded-full
dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600
focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40
dark:focus:border-blue-300 focus:outline-none focus:ring`
          }
          type={props.type}
          placeholder={props.placeholder}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          onKeyPress={props.onKeyPress}
        />
      </div>
    </div>
  );
};
