import { SideNavigation } from "../../shared/components/SideNavigation";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { PopupModal } from "../../shared/components/PopupModal";
import { SingleInput } from "../../shared/components/SingleInput";
import { Button } from "../../shared/components/Button";
import { FileHandler } from "../../shared/components/FileHandler";
import { Link } from "react-router-dom";
import { useOrganization } from "../../shared/hooks/Organization";
import { useNavigate } from "react-router-dom";

export const OrganizationDetail = () => {
  const { organizationUid } = useParams();

  const navigate = useNavigate();
  const {
    currentOrganization,
    orgLocations,
    getOrganizationById,
    getLocationsByOrganizationId,
    updateOrganization,
    createLocation,
    deleteLocation,
  } = useOrganization();

  const [showEditOrganizationPopup, setShowEditOrganizationPopup] =
    useState(false);
  const [showCreateLocationPopup, setShowCreateLocationPopup] = useState(false);
  const [newLocationName, setNewLocationName] = useState("");
  const [editOrganizationName, setEditOrganizationName] = useState("");
  const [editOrganizationLogo, setEditOrganizationLogo] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [showDeleteLocationPopup, setShowDeleteLocationPopup] = useState(false);

  useEffect(() => {
    if (organizationUid) {
      getOrganizationById(organizationUid);
      getLocationsByOrganizationId(organizationUid);
    }
  }, [organizationUid]);

  useEffect(() => {
    if (currentOrganization) {
      setEditOrganizationName(currentOrganization.name);
      setEditOrganizationLogo(currentOrganization.logo);
    }
  }, [currentOrganization]);

  const handleUpdateOrganization = async () => {
    if (organizationUid) {
      await updateOrganization(organizationUid, {
        name: editOrganizationName,
        logo: editOrganizationLogo,
        description: "",
      });
      getOrganizationById(organizationUid);
      setShowEditOrganizationPopup(false);
    }
  };
  const goBack = () => {
    navigate(-1);
  };
  const handleCreateLocation = async () => {
    if (organizationUid) {
      await createLocation(organizationUid, newLocationName);
      setNewLocationName("");
      setShowCreateLocationPopup(false);
    }
  };

  const confirmDeleteLocation = (uid: string) => {
    setSelectedLocation(uid);
    setShowDeleteLocationPopup(true);
  };

  const deleteLocationLocal = async () => {
    try {
      if (!organizationUid) return;
      await deleteLocation(selectedLocation, organizationUid);
      setShowDeleteLocationPopup(false);
      getLocationsByOrganizationId(organizationUid);
    } catch (e) {
      console.error(e);
    }
  };


  return (
    <div className="flex w-full">
      <div className="hidden lg:block">
        <SideNavigation />
      </div>
      <div className="page flex-grow p-4 lg:p-16 w-full lg:w-[80%]">
        <div className="my-4">
          <i
            className="ri-arrow-left-line text-4xl cursor-pointer"
            onClick={goBack}
          ></i>
        </div>
        <div className="my-4">
          <img
            src={currentOrganization?.logo}
            alt="Organization Logo"
            className="w-20 object-contain"
          />
        </div>
        <div className="my-4">
          <h1 className="text-2xl text-left font-semibold dark:text-white">
            {currentOrganization?.name}{" "}
            <i
              className="ri-edit-line text-2xl cursor-pointer"
              onClick={() => setShowEditOrganizationPopup(true)}
            ></i>
          </h1>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 4xl:grid-cols-5 5xl:grid-cols-6 gap-5 my-5"></div>

        <div className="flex items-center justify-between w-full">
          <h1 className="text-2xl text-left font-semibold dark:text-white">
            Locations
          </h1>
          <Button
            onClick={() => setShowCreateLocationPopup(true)}
            title="New"
          />
        </div>

        <table className="table-auto w-full border-collapse border border-gray-200 mt-10">
          <thead className="bg-gray-100 text-left">
            <tr className="border-b">
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orgLocations.map((location) => (
              <tr key={location.uid} className="border">
                <td className="px-4 py-2 border-gray-200">
                  <Link
                    to={`/organizations/${organizationUid}/locations/${location.uid}`}
                  >
                    {location.name}
                  </Link>
                </td>
                <td className="px-4 py-2 border-gray-200">
                  <div className="flex items-center space-x-2">
                    <Link
                      to={`/organizations/${organizationUid}/locations/${location.uid}`}
                    >
                      <i className="ri-pencil-line text-xl" />
                    </Link>
                    <i className="ri-delete-bin-line text-xl text-red-500" 
                      onClick={() => confirmDeleteLocation(location.uid)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Edit Organization Modal */}
      <PopupModal
        title="Edit Organization"
        isShown={showEditOrganizationPopup}
        onToggle={() => setShowEditOrganizationPopup(false)}
        content={
          <div>
            <div className="my-4">
              <p className="text-lg">Logo</p>
              <img
                src={currentOrganization?.logo}
                alt="Organization Logo"
                className="w-20 object-contain"
              />
            </div>
            <FileHandler
              onUploadComplete={(url) => setEditOrganizationLogo(url)}
            />
            <div className="my-4">
              <SingleInput
                type="text"
                label="Organization Name"
                onChange={(e) => setEditOrganizationName(String(e))}
                value={editOrganizationName}
              />
            </div>
            <div className="mt-4 w-full flex items-center justify-end">
              <Button title="Update" onClick={handleUpdateOrganization} />
            </div>
          </div>
        }
      />

      {/* Create Location Modal */}
      <PopupModal
        title="New Location"
        isShown={showCreateLocationPopup}
        onToggle={() => setShowCreateLocationPopup(false)}
        content={
          <div>
            <div className="my-4">
              <div className="mt-10">
                <SingleInput
                  type="text"
                  label="Location Name"
                  onChange={(e) => setNewLocationName(String(e))}
                  value={newLocationName}
                />
              </div>
            </div>
            <div className="mt-4 w-full flex items-center justify-end">
              <Button title="Save" onClick={handleCreateLocation} />
            </div>
          </div>
        }
      />
      <PopupModal
        title="Delete Location"
        isShown={showDeleteLocationPopup}
        onToggle={() => setShowDeleteLocationPopup(false)}
        content={
          <>
            <p>Are you sure you want to delete this location?</p>
            <div className="mt-4 w-full flex items-center justify-end">
              <Button
                title="Delete"
                onClick={deleteLocationLocal}
                className="bg-red-600"
              />
            </div>
          </>
        }
      />
    </div>
  );
};
