import { Link, NavLink, useMatch } from "react-router-dom";
import { useAuth } from "../hooks/Auth";
import { useNavigate } from "react-router-dom";
import { get, ref } from "firebase/database";
import { database } from "../config/firebase";
import { useEffect, useState } from "react";

type NavLink = {
  url: string;
  name: string;
  icon: string;
};

export const SideNavigation = () => {
  const { user, handleSignOut } = useAuth();
  const navigate = useNavigate();
  const [clients, setClients] = useState<any[]>([]);
  const getClients = async () => {
    const clientsRef = ref(database, "clients");
    get(clientsRef).then((snapshot) => {
      if (snapshot.exists()) {
        const clientData = Object.keys(snapshot.val()).map((key) => {
          return { id: key, ...snapshot.val()[key] };
        });
        setClients(clientData);
        console.log(clients);
      } else {
        console.log("No data available");
      }
    });
  };

  const links: NavLink[] = [
    { url: "/dashboard", name: "Dashboard", icon: "ri-stack-fill" },
    { url: "/analytics", name: "Analytics", icon: "ri-pie-chart-2-fill" },
    { url: "/organizations", name: "Organizations", icon: "ri-building-fill" },
    { url: "/locations", name: "Locations", icon: "ri-store-2-fill" },
  ];

  useEffect(() => {
    getClients();
  }, []);

  const logout = () => {
    handleSignOut();
    navigate("/auth/signin");
  };
  return (
    <aside
      className={`flex flex-col w-[20%] lg:max-w-[250px] h-screen px-5 py-5 overflow-y-auto bg-white fixed
                  border-r-2 border-gray-100 dark:border-gray-800
                dark:bg-gray-950 `}
    >
      <div className={"w-full flex items-center "}>
        <Link to="/dashboard" className={"flex items-center"}>
          <h2 className="text-center text-xl dark:text-white">YourTap</h2>
        </Link>
      </div>
      <div className="flex flex-col justify-between flex-1 mt-6">
        <nav className="flex-1 -mx-3 space-y-3 ">
          <div className="relative">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <i className="ri-search-2-line text-gray-300"></i>
            </span>
            <input
              type="text"
              className="w-full py-1.5 pl-8 pr-4 text-gray-700 bg-gray-50
              border rounded-md dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600
              focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300
              focus:ring-opacity-40 focus:outline-none focus:ring"
              placeholder="Search"
            />
          </div>

          <h2 className="text-left text-sm text-gray-500 font-semibold mt-3 mx-2">
            Navigate
          </h2>

          {links.map((link: NavLink) => (
            <NavLink
              to={link.url}
              key={link.url}
              className={
                useMatch(link.url)
                  ? `flex items-center px-3 py-2 text-gray-600 transition-colors
                     duration-300 transform rounded-lg dark:text-gray-300 bg-gray-200
                   dark:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700`
                  : `flex items-center px-3 py-2 text-gray-600 transition-colors
                   duration-300 transform rounded-lg dark:text-gray-300 hover:bg-gray-200
                 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700`
              }
            >
              <i className={link.icon}></i>
              <span className="mx-2 text-sm font-medium">{link.name}</span>
            </NavLink>
          ))}

          <nav
            className="border-t-2 dark:border-gray-800 border-gray-100
            "
          >
            <div className="relative mx-3 mt-3">
         
              <div className="verflow-yscroll ">
                {clients.map((client) => (
                  <NavLink
                    to={`/client/${client.id}`}
                    key={client.id}
                    className={({ isActive }) =>
                      isActive
                        ? `flex items-center px-3 py-2 text-gray-600 transition-colors duration-300
                           transform rounded-lg dark:text-gray-300 bg-gray-200
                         dark:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700`
                        : `flex items-center px-3 py-2 text-gray-600 transition-colors
                           duration-300 transform rounded-lg dark:text-gray-300
                          hover:bg-gray-200 dark:hover:bg-gray-800 dark:hover:text-gray-200
                          hover:text-gray-700`
                    }
                  >
                    <i className="ri-user-fill"></i>
                    <span className="mx-2 text-sm font-medium">
                      {client.name}
                    </span>
                  </NavLink>
                ))}
              </div>
            </div>
          </nav>
        </nav>

        <div className="mt-6">
          <div className="p-3 bg-gray-100 rounded-lg dark:bg-gray-800 hidden">
            <a href="https://yourtap.me" target="_blank" rel="noreferrer">
              <h2 className="text-sm font-medium text-gray-800 dark:text-white">
                Over YourTap
              </h2>

              <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                At YourTap, we have successfully helped over 300 companies in
                more than 15 countries enhance their online reputation, employee
                satisfaction, NPS scores, customer trust and much more.
              </p>

              <img
                className="object-cover w-full h-32 mt-2 rounded-lg"
                src="https://yourtap.nl/wp-content/uploads/2024/01/cool-2048x2048.png"
                alt=""
              />
            </a>
          </div>

          <div className="flex items-center justify-between mt-6">
            <Link to="/" className="flex items-center gap-x-2">
              <div className={`rounded-full bg-[#3f3bf2] text-white text-xs
                               font-medium px-3 py-1.5`}>
                {user?.email?.charAt(0).toUpperCase()}
              </div>
              <span className="text-sm font-medium text-gray-700 dark:text-gray-200">
                {user?.email ?? ""}
              </span>
            </Link>
            <div className={"flex items-center gap-2.5"}>
              <Link
                to={"/account/settings"}
                className={`text-gray-500 transition-colors duration-200
                              rotate-180 dark:text-gray-400 rtl:rotate-0
                            hover:text-blue-500 dark:hover:text-blue-400`}>
                <i className="ri-settings-4-fill"></i>
              </Link>

              <i
                className={`ri-logout-box-r-fill text-gray-500 transition-colors
                            cursor-pointer duration-200 rotate-180 dark:text-gray-400
                            rtl:rotate-0 hover:text-blue-500 dark:hover:text-blue-400`}
                onClick={logout}
              ></i>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};
