import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// import { initializeAuth, indexedDBLocalPersistence } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyASx8cPMKXuFd8oSFRXxcVBTdwcH5c3kY8",
  authDomain: "yourtap-analytics.firebaseapp.com",
  databaseURL: "https://yourtap-analytics-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "yourtap-analytics",
  storageBucket: "yourtap-analytics.firebasestorage.app",
  messagingSenderId: "909117687874",
  appId: "1:909117687874:web:91b38daaf68357977045db"
};

const app = initializeApp(firebaseConfig);
//we want to export auth, storage, and database so it can be used in other files

export const auth = getAuth(app);
export const storage = getStorage(app);
export const database = getDatabase(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app, "europe-west1");
