import logo from "../assets/img/yourtap-logo.avif";

type LogoProps = {
  className?: string;
};

export const Logo = (props: LogoProps) => {
  return (
    <>
      <img src={logo} alt="logo" className={props.className} />
    </>
  );
};
