import { Button } from "../../../../shared/components/Button";
import { PopupModal } from "../../../../shared/components/PopupModal";
import { SingleInput } from "../../../../shared/components/SingleInput";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { functions } from "../../../../shared/config/firebase";
import { httpsCallable } from "firebase/functions";
import { useAuth } from "../../../../shared/hooks/Auth";

interface UrlProvider {
  uid: string;
  name: string;
}

type LocationCardData = {
  uid: string;
  name: string;
  urlProviderUid: string;
  deviceTypeRedirectUrl: boolean;
  androidUrlProviderUid?: string | null;
  iosUrlProviderUid?: string | null;
};

export const LocationCardsQR = () => {
  const [showAddCardPopup, setShowAddCardPopup] = useState(false);
  const [showEditCardPopup, setShowEditCardPopup] = useState(false);
  const [showDeleteCardPopup, setShowDeleteCardPopup] = useState(false);
  const [cards, setCards] = useState<LocationCardData[]>([]);
  const [newCardName, setNewCardName] = useState("");
  const [urlProviders, setUrlProviders] = useState<UrlProvider[]>([]);
  const [selectedUrlProvider, setSelectedUrlProvider] = useState<string>("");
  const [deviceType, setDeviceType] = useState(false);
  const [androidUrlProviderUid, setAndroidUrlProviderUid] = useState("");
  const [iosUrlProviderUid, setIosUrlProviderUid] = useState("");
  const [currentCard, setCurrentCard] = useState<LocationCardData | null>(null);
  const { organizationUid, locationUid } = useParams();
  const { user } = useAuth();

  useEffect(() => {
    getLocationUrlProviders();
    getLocationCards();
  }, []);

  const getLocationUrlProviders = async () => {
    try {
      const locationUrlProviders = httpsCallable<{}, UrlProvider[]>(
        functions,
        "getLocationUrlProviders"
      );
      const locationProviders = await locationUrlProviders({
        organizationUid,
        locationUid,
        userUid: user?.uid,
      });
      setUrlProviders(locationProviders.data);
    } catch (error) {
      console.error("Error getting location providers: ", error);
    }
  };

  const getLocationCards = async () => {
    try {
      const locationCards = httpsCallable<{}, LocationCardData[]>(
        functions,
        "getLocationCards"
      );
      const cards = await locationCards({
        organizationUid,
        locationUid,
        userUid: user?.uid,
      });
      setCards(cards.data);
    } catch (error) {
      console.error("Error getting location cards: ", error);
      setCards([]); // Reset cards to empty array
    }
  };

  const addCard = async () => {
    try {
      const createNewCard = httpsCallable(functions, "createCard");
      const payload = {
        organizationUid,
        locationUid,
        name: newCardName,
        urlProviderUid: selectedUrlProvider,
        deviceTypeRedirectUrl: deviceType,
        androidUrlProviderUid: deviceType ? androidUrlProviderUid : undefined,
        iosUrlProviderUid: deviceType ? iosUrlProviderUid : undefined,
      };
      await createNewCard(payload);
      resetForm();
      setShowAddCardPopup(false);
      getLocationCards();
    } catch (error) {
      console.error("Error adding card: ", error);
    }
  };

  const updateCard = async () => {
    try {
      const updateExistingCard = httpsCallable(functions, "updateCard");
      const payload = {
        cardUid: currentCard?.uid,
        organizationUid,
        locationUid,
        name: newCardName,
        urlProviderUid: selectedUrlProvider,
        deviceTypeRedirectUrl: deviceType,
        androidUrlProviderUid: deviceType ? androidUrlProviderUid : undefined,
        iosUrlProviderUid: deviceType ? iosUrlProviderUid : undefined,
      };
      await updateExistingCard(payload);
      resetForm();
      setShowEditCardPopup(false);
      getLocationCards();
    } catch (error) {
      console.error("Error updating card: ", error);
    }
  };

  const deleteCard = async () => {
    try {
      const deleteExistingCard = httpsCallable(functions, "deleteCard");
      await deleteExistingCard({
        cardUid: currentCard?.uid,
        organizationUid,
        locationUid,
      });
      setShowDeleteCardPopup(false);
      getLocationCards();
    } catch (error) {
      console.error("Error deleting card: ", error);
    }
  };

  const toggleAddCardPopup = () => {
    resetForm();
    setShowAddCardPopup(!showAddCardPopup);
  };

  const openEditCardPopup = (card: LocationCardData) => {
    setCurrentCard(card);
    setNewCardName(card.name);
    setSelectedUrlProvider(card.urlProviderUid);
    setDeviceType(card.deviceTypeRedirectUrl);
    setAndroidUrlProviderUid(card.androidUrlProviderUid || "");
    setIosUrlProviderUid(card.iosUrlProviderUid || "");
    setShowEditCardPopup(true);
  };

  const openDeleteCardPopup = (card: LocationCardData) => {
    setCurrentCard(card);
    setShowDeleteCardPopup(true);
  };

  const resetForm = () => {
    setNewCardName("");
    setSelectedUrlProvider("");
    setDeviceType(false);
    setAndroidUrlProviderUid("");
    setIosUrlProviderUid("");
    setCurrentCard(null);
  };

  const renderUrlProviderName = (urlProviderUid: string) => {
    const provider = urlProviders.find(
      (provider) => provider.uid === urlProviderUid
    );
    return (
      provider?.name || (
        <div className="text-red-500">
          <i className="ri-error-warning-line mr-2"></i>
          No URL provider
        </div>
      )
    );
  };

   const copyToClipboard = (text: string) => {
    if (!navigator.clipboard || !text) {
      return;
    }
    navigator.clipboard.writeText(text);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl text-left font-semibold dark:text-white">
          Cards/QR
        </h1>
        <Button title="Add" onClick={toggleAddCardPopup} />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 p-4">
        {cards.map((card) => (
          <div
            key={card.uid}
            className="bg-white shadow-md rounded-lg p-6 border transform transition-transform"
          >
            <div className="mb-4">
              <div className="text-gray-600 font-semibold">Name</div>
              <div className="w-full flex items-center">
                <div className="font-semibold text-lg">{card.name}</div>
                <i
                  className="ri-edit-line text-primary cursor-pointer text-lg ml-2"
                  onClick={() => openEditCardPopup(card)}
                ></i>
              </div>
            </div>
              <div className="mb-4">
              <div className="text-gray-600 font-semibold">Card ID</div>
              <div
                className="flex items-center text-sm text-gray-500 cursor-pointer"
                onClick={() => copyToClipboard(card.uid)}
              >
                <div>{card.uid}</div>
                <i className="ri-file-copy-line ml-2"></i>
              </div>

            </div>
            <div className="mb-4">
              <div className="text-gray-600 font-semibold">Link</div>
              <div className="text-sm text-gray-500">
                {renderUrlProviderName(card.urlProviderUid)}
              </div>

            </div>
            <div className="mb-4">
              <div className="text-gray-600 font-semibold">Device sepcific link</div>
              <div
                className={`cursor-pointer rounded-full h-6 w-12 flex items-center px-1 ${
                  card.deviceTypeRedirectUrl ? "bg-green-500" : "bg-gray-300"
                }`}
              >
                <div
                  className={`rounded-full h-4 w-4 bg-white transform transition-transform ${
                    card.deviceTypeRedirectUrl ? "translate-x-6" : ""
                  }`}
                ></div>
              </div>
            </div>
            {card.deviceTypeRedirectUrl && (
              <>
                <div className="mb-4">
                  <div className="text-gray-600 font-semibold">
                    Android link
                  </div>
                  <div className="text-sm text-gray-500">
                    {renderUrlProviderName(card.androidUrlProviderUid || "")}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-gray-600 font-semibold">
                    iOS link
                  </div>
                  <div className="text-sm text-gray-500">
                    {renderUrlProviderName(card.iosUrlProviderUid || "")}
                  </div>
                </div>
              </>
            )}
            <div className="flex space-x-2 mt-4">
              <i
                className="ri-delete-bin-line text-red-600 cursor-pointer text-lg"
                onClick={() => openDeleteCardPopup(card)}
              ></i>
            </div>
          </div>
        ))}
      </div>
      <PopupModal
        title="New Card"
        isShown={showAddCardPopup}
        onToggle={toggleAddCardPopup}
        content={
          <>
            <SingleInput
              type="text"
              onChange={(e) => setNewCardName(String(e))}
              label="Card Name"
              value={newCardName}
            />
            <div className="my-4">
              <label htmlFor="urlProvider" className="block mb-2 font-semibold">
                Select URL
              </label>
              <select
                id="urlProvider"
                value={selectedUrlProvider}
                onChange={(e) => setSelectedUrlProvider(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="">Choose a URL</option>
                {urlProviders.map((provider) => (
                  <option key={provider.uid} value={provider.uid}>
                    {provider.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="my-4">
              <label className="block mb-2 font-semibold">Device specific link</label>
              <div
                className={`cursor-pointer rounded-full h-6 w-12 flex items-center px-1 ${
                  deviceType ? "bg-green-500" : "bg-gray-300"
                }`}
                onClick={() => setDeviceType(!deviceType)}
              >
                <div
                  className={`rounded-full h-4 w-4 bg-white transform transition-transform ${
                    deviceType ? "translate-x-6" : ""
                  }`}
                ></div>
              </div>
            </div>
            {deviceType && (
              <>
                <div className="my-4">
                  <label
                    htmlFor="androidProvider"
                    className="block mb-2 font-semibold"
                  >
                    Android URL
                  </label>
                  <select
                    id="androidProvider"
                    value={androidUrlProviderUid}
                    onChange={(e) => setAndroidUrlProviderUid(e.target.value)}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">Choose a URL</option>
                    {urlProviders.map((provider) => (
                      <option key={provider.uid} value={provider.uid}>
                        {provider.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="my-4">
                  <label
                    htmlFor="iosProvider"
                    className="block mb-2 font-semibold"
                  >
                    iOS URL
                  </label>
                  <select
                    id="iosProvider"
                    value={iosUrlProviderUid}
                    onChange={(e) => setIosUrlProviderUid(e.target.value)}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">Choose a URL</option>
                    {urlProviders.map((provider) => (
                      <option key={provider.uid} value={provider.uid}>
                        {provider.name}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
            <Button title="Save" onClick={addCard} />
          </>
        }
      />
      <PopupModal
        title="Edit Card"
        isShown={showEditCardPopup}
        onToggle={() => setShowEditCardPopup(false)}
        content={
          <>
            <SingleInput
              type="text"
              onChange={(e) => setNewCardName(String(e))}
              label="Card Name"
              value={newCardName}
            />
            <div className="my-4">
              <label htmlFor="urlProvider" className="block mb-2 font-semibold">
                Select URL
              </label>
              <select
                id="urlProvider"
                value={selectedUrlProvider}
                onChange={(e) => setSelectedUrlProvider(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="">Choose a URL</option>
                {urlProviders.map((provider) => (
                  <option key={provider.uid} value={provider.uid}>
                    {provider.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="my-4">
              <label className="block mb-2 font-semibold">Device specific link</label>
              <div
                className={`cursor-pointer rounded-full h-6 w-12 flex items-center px-1 ${
                  deviceType ? "bg-green-500" : "bg-gray-300"
                }`}
                onClick={() => setDeviceType(!deviceType)}
              >
                <div
                  className={`rounded-full h-4 w-4 bg-white transform transition-transform ${
                    deviceType ? "translate-x-6" : ""
                  }`}
                ></div>
              </div>
            </div>
            {deviceType && (
              <>
                <div className="my-4">
                  <label
                    htmlFor="androidProvider"
                    className="block mb-2 font-semibold"
                  >
                    Android link
                  </label>
                  <select
                    id="androidProvider"
                    value={androidUrlProviderUid}
                    onChange={(e) => setAndroidUrlProviderUid(e.target.value)}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">Choose a URL</option>
                    {urlProviders.map((provider) => (
                      <option key={provider.uid} value={provider.uid}>
                        {provider.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="my-4">
                  <label
                    htmlFor="iosProvider"
                    className="block mb-2 font-semibold"
                  >
                    iOS link
                  </label>
                  <select
                    id="iosProvider"
                    value={iosUrlProviderUid}
                    onChange={(e) => setIosUrlProviderUid(e.target.value)}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">Choose a URL</option>
                    {urlProviders.map((provider) => (
                      <option key={provider.uid} value={provider.uid}>
                        {provider.name}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
            <Button title="Update" onClick={updateCard} />
          </>
        }
      />
      <PopupModal
        title="Delete Card"
        isShown={showDeleteCardPopup}
        onToggle={() => setShowDeleteCardPopup(false)}
        content={
          <>
            <p>Are you sure you want to delete this card?</p>
            <div className="mt-4 w-full flex items-center justify-end">
              <Button
                title="Delete"
                onClick={deleteCard}
                className="bg-red-600"
              />
            </div>
          </>
        }
      />
    </div>
  );
};

