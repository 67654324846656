import { createContext, useContext, FC, useState, ReactNode } from "react";
import {
  User,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  signInAnonymously,
} from "firebase/auth";
import { auth, functions } from "../config/firebase";
import { httpsCallable } from "firebase/functions";

type AuthContextType = {
  user: User | null;
  isAuthenticated: boolean;
  setUser: (user: User | null) => void;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (name:string, email: string, password: string) => Promise<void>;
  anonymousSignIn: () => void;
  handleSignOut: () => void;
  waitForUserSignIn: () => Promise<boolean>;
};
const AuthContext = createContext<AuthContextType | null>(null);
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
interface AuthProviderProps {
  children: ReactNode;
}
export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const signIn = async (email: string, password: string) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password,
        );
        if (!userCredential.user) {
          reject();
          throw new Error("User not found");
        }
        setIsAuthenticated(true);
        setUser(userCredential.user);
        resolve();
      } catch (error) {
        console.error(error);
        setIsAuthenticated(false);
        reject();
      }
    });
  };

  const signUp = async (name: string, email: string, password: string) => {
    const createUserFunc = httpsCallable<
      { name: string; email: string; password: string },
      { uid: string; message: string }
    >(functions, "createUser");

    return new Promise<void>(async (resolve, reject) => {
      try {
        const response = await createUserFunc({ name, email, password });
        const { uid, message } = response.data;

        console.log(`User created: ${uid}, ${message}`);

        setIsAuthenticated(true);

        resolve();
      } catch (error) {
        console.error("Error creating user:", error);
        setIsAuthenticated(false);
        reject(error);
      }
    });
  };

  const anonymousSignIn = async () => {
    try {
      const userCredential = await signInAnonymously(auth);
      if (!userCredential.user) {
        throw new Error("User not found");
      }
      setIsAuthenticated(true);
      setUser(userCredential.user);
      console.log(userCredential.user);
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setIsAuthenticated(false);
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
    }
  };

  const waitForUserSignIn = () => {
    return new Promise<boolean>((resolve) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          setUser(user);
          setIsAuthenticated(true);
          resolve(true);
        } else {
          setUser(null);
          setIsAuthenticated(false);
          resolve(false);
        }
        unsubscribe();
      });
    });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        setUser,
        signIn,
        signUp,
        handleSignOut,
        anonymousSignIn,
        waitForUserSignIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
