import { Route, Routes } from "react-router-dom";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { Organizations } from "./pages/organizations/Organizations";
import { OrganizationDetail } from "./pages/organizations/OrganizationDetail";
import { Analytics} from "./pages/analytics/Analytics";
import { Locations } from "./pages/locations/Locations";
import { Users } from "./pages/users/Users";
import { LocationDetail } from "./pages/organizations/locations/LocationDetail";
import { OrganizationProvider } from "./shared/hooks/Organization";
export const AuthenticatedRoutesController = () => {
  return (
    <div className={"flex w-full"}>
      <OrganizationProvider>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/organizations" element={<Organizations />} />
        <Route path="/organizations/:organizationUid/locations" element={<OrganizationDetail />} />
        <Route path="/organizations/:organizationUid/locations/:locationUid" element={<LocationDetail />} />
        <Route path="analytics" element={<Analytics />} />
        <Route path="/locations" element={<Locations />} />
        <Route path="/users" element={<Users />} />
      </Routes>
      </OrganizationProvider>
    </div>
  );
};
