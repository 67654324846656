import { useState } from 'react';
import { storage } from '../../shared/config/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

interface FileHandlerProps {
  onUploadComplete: (url: string) => void;
}

export const FileHandler = ({ onUploadComplete }: FileHandlerProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [downloadURL, setDownloadURL] = useState<string | null>(null);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      uploadFile(file);
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
      uploadFile(file);
    }
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const uploadFile = (file: File) => {
    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error('File upload error: ', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setDownloadURL(url);
          onUploadComplete(url); // Emit the URL
        });
      }
    );
  };

  return (
    <div className="flex justify-center items-center">
      <div
        className="text-center p-10 border-2 border-dashed border-primary rounded-lg w-80"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <div className="mb-5">
          <i className="ri-file-text-line text-4xl text-primary"></i>
        </div>
        <input
          type="file"
          onChange={handleFileChange}
          className="hidden"
          id="fileInput"
        />
        <label
          htmlFor="fileInput"
          className="bg-white text-black py-2 px-4 rounded cursor-pointer"
        >
          Browse File
        </label>

        {progress > 0 && (
          <div className="mt-4">
            <p className="text-white">Upload Progress: {Math.round(progress)}%</p>
          </div>
        )}

        {downloadURL && (
          <div className="mt-4">
            <a
              href={downloadURL}
              className=" underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={downloadURL} alt="file" className="w-20 h-20" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

