interface PopupModalProps {
  // content to render in html like a form or a message
  content: JSX.Element;
  onToggle: () => void;
  isShown: boolean;
  title?: string;
}

export const PopupModal = (props: PopupModalProps) => {
  return props.isShown ? (
    <div className="w-full h-full fixed top-0 left-0 backdrop-blur-lg">
      <div
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 min-w-96
        -translate-y-1/2 bg-white p-4 rounded-lg border-2"
      >
        <h3 className="font-semibold text-xl">{props?.title}</h3>
        <i
          className="ri-close-line text-4xl absolute top-2 right-2 cursor-pointer
          "
          onClick={props.onToggle}
        ></i>
        <div className="mt-10">{props.content}</div>
      </div>
    </div>
  ) : null;
};
