import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { AuthenticatedRoutesController } from "./AuthenticatedRoutesController";
import { SignIn } from "./pages/auth/SignIn";
import { SignUp } from "./pages/auth/SignUp";
import "remixicon/fonts/remixicon.css";

const App = () => {
  return (
    <div className="App">
      <div className="App h-screen dark:bg-gray-900">
        <Routes>
          <Route path="/auth/signin" element={<SignIn />} />
          <Route path="/auth/signup" element={<SignUp />} />
          <Route
            path="*"
            element={
              <ProtectedRoute component={AuthenticatedRoutesController} />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default App;
