import { Button } from "../../../../shared/components/Button";
import { PopupModal } from "../../../../shared/components/PopupModal";
import { SingleInput } from "../../../../shared/components/SingleInput";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { functions } from "../../../../shared/config/firebase";
import { httpsCallable } from "firebase/functions";
import { useAuth } from "../../../../shared/hooks/Auth";

interface UrlProvider {
  uid: string;
  name: string;
  redirectUrl: string;
}

export const LocationUrlProviders = () => {
  const [showAddCustomProviderPopup, setShowAddCustomProviderPopup] =
    useState(false);
  const [showEditProviderPopup, setShowEditProviderPopup] = useState(false);
  const [showDeleteProviderPopup, setShowDeleteProviderPopup] = useState(false);
  const [newCustomUrlProviderName, setNewCustomUrlProviderName] = useState("");
  const [newUrl, setNewUrl] = useState("");
  const [editProviderName, setEditProviderName] = useState("");
  const [editUrl, setEditUrl] = useState("");
  const [selectedProvider, setSelectedProvider] = useState<UrlProvider | null>(
    null,
  );
  const [urlProviders, setUrlProviders] = useState<UrlProvider[]>([]);
  const { organizationUid, locationUid } = useParams();
  const { user } = useAuth();

  useEffect(() => {
    getLocationUrlProviders();
  }, []);

  const addCustomRedirectUrl = async () => {
    try {
      const createNewCustomRedirectUrl = httpsCallable(
        functions,
        "createLocationUrlProvider",
      );
      await createNewCustomRedirectUrl({
        organizationUid,
        name: newCustomUrlProviderName,
        locationUid,
        redirectUrl: newUrl,
      });
      setNewCustomUrlProviderName("");
      setNewUrl("");
      setShowAddCustomProviderPopup(false);
      getLocationUrlProviders();
    } catch (error) {
      console.error("Error adding location: ", error);
    }
  };

  const getLocationUrlProviders = async () => {
    try {
      const locationUrlProviders = httpsCallable<{}, UrlProvider[]>(
        functions,
        "getLocationUrlProviders",
      );
      const locationProviders = await locationUrlProviders({
        organizationUid,
        locationUid,
        userUid: user?.uid,
      });
      setUrlProviders(locationProviders.data);
    } catch (error) {
      console.error("Error getting location providers: ", error);
      setUrlProviders([]); // if error is thrown empty state
    }
  };

  const toggleAddCustomProviderPopup = () => {
    setShowAddCustomProviderPopup(!showAddCustomProviderPopup);
  };

  const openEditProviderPopup = (provider: UrlProvider) => {
    setSelectedProvider(provider);
    setEditProviderName(provider.name);
    setEditUrl(provider.redirectUrl);
    setShowEditProviderPopup(true);
  };

  const closeEditProviderPopup = () => {
    setShowEditProviderPopup(false);
    setSelectedProvider(null);
    setEditProviderName("");
    setEditUrl("");
  };

  const updateProvider = async () => {
    if (!selectedProvider) return;
    try {
      const updateLocationUrlProvider = httpsCallable(
        functions,
        "updateLocationUrlProvider",
      );
      await updateLocationUrlProvider({
        organizationUid,
        locationUid,
        providerUid: selectedProvider.uid,
        name: editProviderName,
        redirectUrl: editUrl,
      });
      closeEditProviderPopup();
      getLocationUrlProviders();
    } catch (error) {
      console.error("Error updating provider: ", error);
    }
  };

  const openDeleteProviderPopup = (provider: UrlProvider) => {
    setSelectedProvider(provider);
    setShowDeleteProviderPopup(true);
    getLocationUrlProviders();
  };

  const deleteProvider = async () => {
    if (!selectedProvider) return;
    try {
      const deleteLocationUrlProvider = httpsCallable(
        functions,
        "deleteLocationUrlProvider",
      );
      await deleteLocationUrlProvider({
        organizationUid,
        locationUid,
        providerUid: selectedProvider.uid,
      });
      setShowDeleteProviderPopup(false);
      getLocationUrlProviders();
    } catch (error) {
      console.error("Error deleting provider: ", error);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl text-left font-semibold dark:text-white">
          Custom URL Providers
        </h1>
        <Button title="Add" onClick={toggleAddCustomProviderPopup} />
      </div>
      <div>
        <table className="table-auto w-full border-collapse border border-gray-200 mt-10">
          <thead className="bg-gray-100 text-left">
            <tr className="border-b">
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">URL</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {urlProviders.map((provider) => (
              <tr key={provider.uid} className="border">
                <td
                  className="px-4 py-2 border-gray-200 cursor-pointer"
                  onClick={() => openEditProviderPopup(provider)}
                >
                  {provider.name}
                </td>
                <td
                  className="px-4 py-2 border-gray-200 cursor-pointer"
                  onClick={() => openEditProviderPopup(provider)}
                >
                  {provider.redirectUrl}
                </td>
                <td className="px-4 py-2 border-gray-200">
                  <div className="flex items-center space-x-2">
                    <i
                      className="ri-pencil-line text-xl cursor-pointer"
                      onClick={() => openEditProviderPopup(provider)}
                    />
                    <i
                      className="ri-delete-bin-line text-xl cursor-pointer text-red-500"
                      onClick={() => openDeleteProviderPopup(provider)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Add Provider Popup */}
      <PopupModal
        title="New URL Provider"
        isShown={showAddCustomProviderPopup}
        onToggle={toggleAddCustomProviderPopup}
        content={
          <div>
            <div className="my-4">
              <SingleInput
                type="text"
                onChange={(e) => setNewCustomUrlProviderName(String(e))}
                label="Name of the provider"
                value={newCustomUrlProviderName}
              />
            </div>
            <div className="my-4">
              <SingleInput
                type="text"
                onChange={(e) => setNewUrl(String(e))}
                label="URL"
                value={newUrl}
              />
            </div>
            <div className="my-4">
              <Button title="Save" onClick={addCustomRedirectUrl} />
            </div>
          </div>
        }
      />

      {/* Edit Provider Popup */}
      <PopupModal
        title="Edit URL Provider"
        isShown={showEditProviderPopup}
        onToggle={closeEditProviderPopup}
        content={
          <div>
            <div className="my-4">
              <SingleInput
                type="text"
                onChange={(e) => setEditProviderName(String(e))}
                label="Name of the provider"
                value={editProviderName}
              />
            </div>
            <div className="my-4">
              <SingleInput
                type="text"
                onChange={(e) => setEditUrl(String(e))}
                label="URL"
                value={editUrl}
              />
            </div>
            <div className="my-4">
              <Button title="Update" onClick={updateProvider} />
            </div>
          </div>
        }
      />

      {/* Delete Provider Confirmation Popup */}
      <PopupModal
        title="Delete URL Provider"
        isShown={showDeleteProviderPopup}
        onToggle={() => setShowDeleteProviderPopup(false)}
        content={
          <div>
            <p>Are you sure you want to delete this provider?</p>
            <div className="mt-4 w-full flex items-center justify-end">
              <Button
                title="Delete"
                onClick={deleteProvider}
                className="bg-red-600"
              />
            </div>
          </div>
        }
      />
    </div>
  );
};
