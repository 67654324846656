import { Navigate } from 'react-router-dom';
import { useAuth } from './shared/hooks/Auth';
import { FC, ComponentType, useState, useEffect } from 'react';

type ProtectedRouteProps = {
  component: ComponentType<any>;
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({ component: Component, ...props }) => {
  const { waitForUserSignIn } = useAuth();
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const determineAuthenticated = async () => {
      const isAuth = await waitForUserSignIn();
      setAuthenticated(isAuth);
      setLoading(false);
    };

    determineAuthenticated();
  }, [waitForUserSignIn]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!authenticated) {
    return <Navigate to="/auth/signin" />;
  }

  return <Component {...props} />;
};

export { ProtectedRoute };

