import { SideNavigation } from "../../shared/components/SideNavigation";
import { get, ref } from "firebase/database";
import { database } from "../../shared/config/firebase";
import { useState, useEffect } from "react";
export const Dashboard = () => {
  const [organizations, setOrganizations] = useState<any[]>([]);
  const getClients = async () => {
    const organizationsRef = ref(database, "organizations");
    get(organizationsRef).then((snapshot) => {
      if (snapshot.exists()) {
        const organizationsData = Object.keys(snapshot.val()).map((key) => {
          return { id: key, ...snapshot.val()[key] };
        });
        setOrganizations(organizationsData);
        console.log(organizations);
      } else {
        console.log("No data available");
      }
    });
  };

  useEffect(() => {
    getClients();
  }, []);
  return (
    <div className="flex">
      <div className={"hidden lg:block"}>
        <SideNavigation />
      </div>
      <div className="page flex-grow p-4 lg:p-16 lg:w-[80%]">
        <h1
          className="text-2xl text-left font-semibold dark:text-white
          "
        >
          Dashboard
        </h1>
        <div
          className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 
          xl:grid-cols-4 4xl:grid-cols-5 5xl:grid-cols-6 gap-5 my-5 "
        >
          <div
            className={`bg-white cursor-pointer relative min-w-60 w-full
              dark:bg-gray-800 rounded-lg shadow-sm  border-2 py-6 px-10
                flex items-center justify-between
              `}
          >
            <div className="flex items-center gap-2">
              <i
                className={`ri-user-3-line text-2xl font-semibold text-center
                         text-secondary`}
              ></i>
              <h2 className="text-lg font-semibold dark:text-white">Clients</h2>
            </div>
            <h2 className="text-2xl font-bold text-secondary text-center">
              {organizations.length}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};
