import { SideNavigation } from "../../shared/components/SideNavigation";
export const Locations = () => {
  return (
    <div className="flex">
      <div className={"hidden lg:block"}>
        <SideNavigation />
      </div>
      <div className="page flex-grow p-4 lg:p-16">
        <h1
          className="text-2xl text-left font-semibold dark:text-white
          "
        >
          Locations
        </h1>
        <div
          className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 
          xl:grid-cols-4 4xl:grid-cols-5 5xl:grid-cols-6 gap-5 my-5 "
        ></div>
      </div>
    </div>
  );
};
