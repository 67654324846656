type ButtonProps = {
  title?: string;
  onClick: () => void;
  disabled?: boolean;
  icon?: string;
  className?: string;
};

export const Button = (props: ButtonProps) => {
  return (
    <button
      className={`flex items-center px-3 py-2 font-medium text-center
                        justify-center tracking-wide text-white capitalize
                        transition-colors duration-300 transform rounded-full
                        bg-gradient-to-br from-gradientStart to-gradientEnd
                        focus:outline-none focus:ring focus:ring-blue-300
                        focus:ring-opacity-80 text-xl ${
                          props.className ? props.className : ""
                        }`}
      onClick={() => props.onClick()}
      disabled={props.disabled}
    >
      {props.icon && (
        <div className="mr-2">
          <i className={props.icon} />
        </div>
      )}
      {props.title}
    </button>
  );
};
