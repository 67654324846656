import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { Logo } from "../../shared/components/Logo";
import { SingleInput } from "../../shared/components/SingleInput";
import { Button } from "../../shared/components/Button";
import { useAuth } from "../../shared/hooks/Auth";
export const SignUp = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [name, setName] = React.useState("");
  const { signUp, signIn } = useAuth();
  const navigate = useNavigate();
  const handleSignUp = () => {
    signUp(name, email, password)
      .then(() => {
        signIn(email, password)
          .then(() => {
            navigate("/dashboard");
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="">
      <div className="Login ">
        <section className="bg-white dark:bg-gray-900">
          <div className=" px-6w-full pt-20">
            <div className="flex justify-center mx-auto  max-w-md ">
              <div className={"flex flex-col items-center"}>
                <Logo className={"w-28"} />
                <h1 className={"text-center text-4xl my-5 dark:text-white"}>
                  YourTap
                </h1>
              </div>
            </div>
          </div>
          <div className="container  mt-5 md:mt-20  flex justify-center px-6 mx-auto">
            <div className="w-full max-w-md">
              <div className="flex items-center justify-center mt-6">
                <Link
                  to="/auth/signin"
                  className="w-1/3 pb-4 font-medium text-center text-gray-500 border-b-2 capitalize dark:border-secondary dark:text-gray-300"
                >
                  sign in
                </Link>

                <Link
                  to="/auth/signup"
                  className="w-1/3 pb-4 font-medium text-center text-gray-800 capitalize  border-b-2  border-secondary  dark:text-white"
                >
                  sign up
                </Link>
              </div>

              <section className="inputs">
                <form>
                  <div>
                    <SingleInput
                      label={"Name"}
                      type={"text"}
                      value={name}
                      onChange={(event) => setName(String(event))}
                      icon={"ri-user-line"}
                      placeholder={"Your name"}
                    />
                    <div>
                      <SingleInput
                        label={"Email address"}
                        type={"email"}
                        value={email}
                        onChange={(event) => setEmail(String(event))}
                        icon={"ri-mail-line"}
                        placeholder={"your@email.com"}
                      />
                    </div>

                    <div>
                      <SingleInput
                        label={"Password"}
                        type={"password"}
                        value={password}
                        onChange={(event) => setPassword(String(event))}
                        icon={"ri-lock-line"}
                        placeholder={"Your password"}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            handleSignUp();
                          }
                        }}
                      />
                    </div>
                  </div>
                </form>
              </section>
              <div className="mt-6">
                <Button
                  title={"Sign up"}
                  onClick={handleSignUp}
                  icon={"ri-login-box-line"}
                  className={"w-full"}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
