import { SideNavigation } from "../../../shared/components/SideNavigation";
import { useState, useEffect } from "react";
import { LocationAnalytics } from "./tabs/LocationAnalytics";
import { LocationSettings } from "./tabs/LocationSettings";
import { LocationUsers } from "./tabs/LocationUsers";
import { LocationCardsQR } from "./tabs/LocationCardsQR";
import { LocationUrlProviders } from "./tabs/LocationUrlProviders";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "../../../shared/hooks/Organization";
export const LocationDetail = () => {
  const navigate = useNavigate();
  const { locationUid, organizationUid } = useParams();
  const [currentActiveTab, setCurrentActiveTab] = useState("analytics");
  const [currentLocationName, setCurrentLocationName] = useState("");
  const [tabsList, setTabsList] = useState([
    { title: "Analytics", id: "analytics" },
    { title: "Settings", id: "settings" },
    { title: "Users", id: "users" },
    { title: "Cards/QR", id: "cards" },
    { title: "Url providers", id: "url" },
  ]);

  const { orgLocations, getLocationsByOrganizationId } = useOrganization();

  useEffect(() => {
    if (locationUid) {
      if (orgLocations.length === 0) {
         getLocationsByOrganizationId(organizationUid ?? "");
      }
      console.log("locationUid", locationUid);
      console.log("orgLocations", orgLocations);

      const location = orgLocations.find((loc) => loc.uid === locationUid);
      console.log("location", location);
      if (location) {
        setCurrentLocationName(location.name);
      }
    }
  }, [locationUid, orgLocations.length]);



  const renderContent = () => {
    switch (currentActiveTab) {
      case "analytics":
        return <LocationAnalytics locationUid={locationUid ?? ""} />;
      case "settings":
        return <LocationSettings />;
      case "users":
        return <LocationUsers />;
      case "cards":
        return <LocationCardsQR />;
      case "url":
        return <LocationUrlProviders />;
      default:
        return null;
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex w-full">
      <div className="hidden lg:block">
        <SideNavigation />
      </div>
      <div className="page flex-grow p-4 lg:p-16 w-full lg:w-[80%] h-full">
        <div className="my-4">
          <i
            className="ri-arrow-left-line text-4xl cursor-pointer"
            onClick={goBack}
          ></i>
        </div>
        <h1 className="text-2xl text-left font-semibold dark:text-white">
          {currentLocationName ?? "Location"}
          
        </h1>
        <div className="flex items-center w-full gap-3 mt-10">
          {tabsList.map((tab) => (
            <div key={tab.id}>
              <div
                onClick={() => setCurrentActiveTab(tab.id)}
                className={`px-4 py-2 rounded-full cursor-pointer transition-all duration-300
${
  currentActiveTab === tab.id
    ? "text-white border-blue-500 bg-gradient-to-br from-gradientStart to-gradientEnd"
    : "text-gray-500"
}`}
              >
                {tab.title}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-5 w-full h-1 bg-primary rounded-full opacity-20"></div>
        <div className="mt-5">{renderContent()}</div>
      </div>
    </div>
  );
};
