import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../shared/components/Button";
import { SideNavigation } from "../../shared/components/SideNavigation";
import { PopupModal } from "../../shared/components/PopupModal";
import { SingleInput } from "../../shared/components/SingleInput";
import { FileHandler } from "../../shared/components/FileHandler";
import { useOrganization } from "../../shared/hooks/Organization";

export const Organizations = () => {
  const { organizations, createOrganization, getOrganizations, deleteOrganization } =
    useOrganization();
  const [newOrganization, setNewOrganization] = useState({
    name: "",
    logo: "",
    description: "",
  });
  const [showCreateOrganizationPopup, setShowCreateOrganizationPopup] =
    useState(false);

  const [showDeleteOrganizationPopup, setShowDeleteOrganizationPopup] =
    useState(false);

  const [selectedOrganization, setSelectedOrganization] = useState("");

  const toggleCreateOrganizationPopup = () =>
    setShowCreateOrganizationPopup(!showCreateOrganizationPopup);

  const handleCreateOrganization = async () => {
    await createOrganization(newOrganization);
    toggleCreateOrganizationPopup();
  };

  const confirmDeleteOrganization = (uid: string) => {
    setSelectedOrganization(uid);
    setShowDeleteOrganizationPopup(true);
  };

  const deleteOrganizationLocal = async () => {
    try {
    await deleteOrganization(selectedOrganization);
    setShowDeleteOrganizationPopup(false);
    }
    catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  return (
    <div className="flex w-full">
      <div className="hidden lg:block">
        <SideNavigation />
      </div>
      <div className="page flex-grow p-4 lg:p-16 w-full lg:w-[80%] min-h-screen">
        <div className="flex items-center justify-between w-full">
          <h1 className="text-2xl font-semibold">Organizations</h1>
          <Button onClick={toggleCreateOrganizationPopup} title="New" />
        </div>
        <table className="table-auto w-full border-collapse mt-10">
          <thead className="bg-gray-100">
            <tr className="border-b text-left">
              <th className="px-4 py-2">Logo</th>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Description</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {organizations.map((org) => (
              <tr key={org.id} className="text-left border">
                <td className="px-4 py-2 flex items-center space-x-3">
                  <Link to={`/organizations/${org.id}/locations`}>
                    <img
                      src={org.logo}
                      alt="logo"
                      className="size-12 object-contain"
                    />
                  </Link>
                </td>
                <td className="px-4 py-2  border-gray-200">
                  <Link to={`/organizations/${org.id}/locations`}>
                    {org.name}
                  </Link>
                </td>
                <td className="px-4 py-2  border-gray-200">
                  <Link to={`/organizations/${org.id}/locations`}>
                    {org.description}
                  </Link>
                </td>
                <td className="px-4 py-2  border-gray-200">
                  <div className="flex items-center space-x-2">
                    <Link to={`/organizations/${org.id}/locations`}>
                      <i className="ri-pencil-line text-xl" />
                    </Link>
                    <i
                      className="ri-delete-bin-line text-xl text-red-500"
                      onClick={() => confirmDeleteOrganization(org.id)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <PopupModal
          title="New Organization"
          isShown={showCreateOrganizationPopup}
          onToggle={toggleCreateOrganizationPopup}
          content={
            <div>
              <div className="my-4">
                <FileHandler
                  onUploadComplete={(logo) =>
                    setNewOrganization((prev) => ({ ...prev, logo }))
                  }
                />
              </div>
              <div className="my-4">
                <SingleInput
                  type="text"
                  label="Organization Name"
                  onChange={(e) =>
                    setNewOrganization((prev) => ({ ...prev, name: String(e) }))
                  }
                  value={newOrganization.name}
                />
              </div>
              <div className="my-4">
                <Button onClick={handleCreateOrganization} title="Save" />
              </div>
            </div>
          }
        />
        <PopupModal
          title="Delete Organization"
          isShown={showDeleteOrganizationPopup}
          onToggle={() => setShowDeleteOrganizationPopup(false)}
          content={
            <>
              <p>Are you sure you want to delete this organization?</p>
              <div className="mt-4 w-full flex items-center justify-end">
                <Button
                  title="Delete"
                  onClick={deleteOrganizationLocal}
                  className="bg-red-600"
                />
              </div>
            </>
          }
        />
      </div>
    </div>
  );
};
