import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { functions } from "../../shared/config/firebase";
import { SideNavigation } from "../../shared/components/SideNavigation";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type VisitData = {
  [locationName: string]: {
    [year: string]: {
      [month: string]: {
        [day: string]: {
          [hour: string]: number; // Visit counts per hour
        };
      };
    };
  };
};

type AllVisitsData = {
  visitData: VisitData;
};

export const Analytics = () => {
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [locationDatasets, setLocationDatasets] = useState<any[]>([]);
  const [totalVisits, setTotalVisits] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>(new Date().toISOString().split("T")[0]);
  const [endDate, setEndDate] = useState<string>(new Date().toISOString().split("T")[0]);

  useEffect(() => {
    fetchAllLocationsVisits();
  }, [startDate, endDate]);

  const fetchAllLocationsVisits = async () => {
    const getAllUserLocationsVisits = httpsCallable<{}, AllVisitsData>(functions, "getAllUserLocationVisits");
    try {
      const { data } = await getAllUserLocationsVisits();
      processAllVisitsData(data.visitData);
    } catch (error) {
      console.error("Error fetching visits for all locations: ", error);
    }
  };

  const processAllVisitsData = (visitData: VisitData) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const labels: string[] = [];
    const datasets: { [location: string]: number[] } = {};
    const localTimeOffset = new Date().getTimezoneOffset() / -60;
    let calculatedTotalVisits = 0;

    for (const locationName in visitData) {
      const locationVisits: number[] = [];
      let currentDate = new Date(start);

      while (currentDate <= end) {
        const year = currentDate.getFullYear().toString();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");

        if (visitData[locationName]?.[year]?.[month]?.[day]) {
          const hours = visitData[locationName][year][month][day];
          for (let hour = 0; hour < 24; hour++) {
            const hourString = hour.toString().padStart(2, "0");
            const visitCount = hours[hourString] || 0;

            const localHour = (hour + localTimeOffset + 24) % 24;
            const label = `${year}-${month}-${day} ${localHour.toString().padStart(2, "0")}:00`;

            if (!labels.includes(label)) {
              labels.push(label);
            }

            locationVisits.push(visitCount);
            calculatedTotalVisits += visitCount;
          }
        } else {
          for (let hour = 0; hour < 24; hour++) {
            const localHour = (hour + localTimeOffset + 24) % 24;
            const label = `${year}-${month}-${day} ${localHour.toString().padStart(2, "0")}:00`;

            if (!labels.includes(label)) {
              labels.push(label);
            }

            locationVisits.push(0);
          }
        }

        currentDate.setDate(currentDate.getDate() + 1);
      }

      datasets[locationName] = locationVisits;
    }

    const chartDatasets = Object.keys(datasets).map((locationName, index) => ({
      label: locationName,
      data: datasets[locationName],
      backgroundColor: `rgba(${(index * 50) % 255}, ${(index * 100) % 255}, ${(index * 150) % 255}, 0.5)`,
      borderColor: `rgba(${(index * 50) % 255}, ${(index * 100) % 255}, ${(index * 150) % 255}, 1)`,
      borderWidth: 1,
    }));

    setChartLabels(labels);
    setLocationDatasets(chartDatasets);
    setTotalVisits(calculatedTotalVisits);
  };

  const calculateStepSize = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const daysDifference = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);

    if (daysDifference <= 1) return 1; // hourly labels for 1 day
    else if (daysDifference <= 7) return 24; // daily labels for up to 7 days
    else if (daysDifference <= 30) return 168; // weekly labels for up to 30 days
    else return Math.ceil(daysDifference / 7) * 24; // larger intervals for more than 30 days
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: { beginAtZero: true },
      x: {
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          stepSize: calculateStepSize(), // Dynamically set step size based on date range
        },
      },
    },
  };

  const chartData = {
    labels: chartLabels,
    datasets: locationDatasets,
  };

  return (
    <div className="flex">
      <div className="hidden lg:block">
        <SideNavigation />
      </div>
      <div className="page flex-grow p-4 lg:p-16 w-full lg:w-[80%] h-full">
        <h1 className="text-2xl font-semibold dark:text-white mb-4">Total Visits: {totalVisits}</h1>
        <div className="flex space-x-4 my-10">
          <div>
            <label>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="border p-2"
            />
          </div>
          <div>
            <label>End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="border p-2"
            />
          </div>
        </div>
        <div style={{ height: "600px" }}>
          <Bar data={chartData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
};

